<template>
  <div class="main-body">
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="登录方式">
        <a-checkbox-group v-model:value="formState.login">
          <a-checkbox value="1" name="login">账号</a-checkbox>
          <a-checkbox value="2" name="login">手机号</a-checkbox>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from "vue";
import * as Api from "@/views/setting/api";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {},
  setup() {
    const formState = reactive({
      login: [],
    });

    const onSubmit = () => {
      Api.save_setting(formState).then((res) => {
        message.success(res.message);
      });
    };

    const getInitSetting = () => {
      Api.get_global_setting().then((res) => {
        formState.login = res.data.login ? res.data.login : [];
      });
    };

    onMounted(() => {
      getInitSetting();
    });

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>